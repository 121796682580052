import { defineStore } from "pinia";

export const users = defineStore('users', {
    state: () => ({
        leads: [],
        partners: [],
        logged: {},
        user: {},
        notifications: []
    })
})