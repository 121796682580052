<template>
    <div class="panel--main">
        <AdminNav />

    <div class="lead-section panel-x">
        <h2 class="panel-x__title center">Instatap Partners</h2> 
    
        <ul class="partner-list">
            <li class="grid" v-for="(item, index) in partners" :key="index">
            <div>
                <p>{{item.fname}}</p> <p>{{item.lname}}</p>
            </div>
            <div>
                <p>{{item.email}}</p> <p>{{item.phone}}</p>
            </div>
            <div class="partner-phone left">
                Partner referal ID: <p>{{item.partner}}</p>
            </div>
            <div>
              <b-tooltip type="is-light" label="Set partner current balance">
              <b-field>
                <b-input icon="currency-gbp" v-model="item.earned"></b-input>
                <p class="control">
                    <b-button type="is-primary" @click="earned(item)" label="Set" />
                </p>
                 </b-field>
                </b-tooltip>
              </div>
            </li>
          </ul>

        <empty v-show="partners.length == 0"><h1>No partners</h1></empty>

      </div>
    </div>
</template>





<script>
import AdminNav from '@/components/AdminNav.vue'
import Empty from '@/components/Empty.vue'

import { db, adminUID } from '../../utils'
import { users } from '@/stores/index'


export default {
    name: 'Partners',
    components: {
        AdminNav, Empty
    },
    data: () => ({
        partners: [], store: users()
    }),
    created(){
        if(this.store.logged.uid !== adminUID) return this.$router.push('/admin')
        this.init()
    },
    methods:{
        init(){
           this.partners = this.store.partners
        },
        earned(item){
            db.collection('partners').doc(item.oid).update({earned: item.earned})
              this.$buefy.toast.open({
                message: 'Partner earnings set!',
                type: 'is-success'
             })
        }
    }
}
</script>




<style lang="scss" scoped>
@import '../../assets/scss/style.scss';


.partner-list{
    padding-top: 80px;
    li{
        grid-template-columns: 16% 25% 30% 22%;
        grid-gap: 20px;
       // background: $bg-color;
        border: 1px solid #e3e3e3;
        padding: 20px;
    }

    i{
        font-size: 40px;
    }

    .partner-phone{
       p{ 
           font-size: 14px;
       }
    }
}





</style>