<template>
    <div>
        <div class="sidebar">
            <router-link to="/admin/app"> <div><i class='bx bx-leaf'></i></div> <span>Leads & clients</span></router-link>
            <router-link to="/admin/partners"> <div><i class='bx bx-group'></i></div> <span>Partners</span></router-link>
            <a href="#" @click.prevent="open = true"><div><i class='bx bx-bell'></i></div> <span>Notifications</span></a>
            <a href="#" @click.prevent="signOut"><div><i class='bx bx-power-off'></i></div> <span>Sign out</span></a>
        </div>

      <b-sidebar type="is-light" overlay fullheight="fullheight" right v-model="open">
      



      <div class="modal-card">
          <div class="modal-card-head">
              <p class="modal-card-title">Notifications</p>
          </div>
            <ul class="alert-list">
                <li v-for="(item, index) in alerts" :key="index">
                    <h4>@ {{item.name}}</h4>
                    <div class="mt-2">{{item.msg}}</div>
                    <div class="flex">
                        <small class="mt-4"><i>{{item.date}}</i></small> <b-button @click="mark(item)" size="is-small" type="is-ghost is-light" :disabled="item.read">{{item.read ? 'Read' : 'Mark as read'}}</b-button>
                    </div>
                </li>
            </ul>
      </div>


    </b-sidebar>
    </div>
</template>


<script>
import { db } from '../utils'
import firebase from 'firebase'


export default {
    name: 'AdminNav',
    data: () => ({
        open: false, alerts: [], 
    }),
    created(){
        db.collection('notifications').onSnapshot((snap) => {
            this.alerts = []
            snap.docs.forEach(i => {
                let item = {...i.data(), ...{oid: i.id}}
                this.alerts.push(item)
            })
            this.$root.$emit('Leads', this.alerts)
        })
    },
    methods:{
        mark(item){
            db.collection('notifications').doc(item.oid).update({read: true})
             this.$buefy.toast.open({
                message: 'Notification marked as read!',
                type: 'is-success'
             })
        },
        signOut(){
            firebase.auth().signOut().then(() => {
                this.$router.push('/admin')
            })
        }
    }

}
</script>


<style lang="scss" scoped>
@import url("https://unpkg.com/boxicons@2.1.2/css/boxicons.min.css");
@import '../assets/scss/style';


::-webkit-scrollbar {
  width: 1px;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}


.alert-list{
  padding: 20px;
  width: 240px;
  height: 100vh;
  overflow-y: auto;
  li{
      border-bottom: $border;
      padding: 10px 0;
      h4{
          font-weight: 600;
      }
      div{
          font-size: 13px;
          word-break: break-all;
      }
      small{
          color: gray;
          font-size: 11px;
      }
      button{
          margin-top: 10px;
      }
  }
}

</style>