<template>
    <div>
        <div class="empty-lead">
            <img src="../assets/account.png">
            <slot></slot>
        </div>
    </div>
</template>


<script>
export default {
    name: 'Empty'
}
</script>

<style lang="scss">
.empty-lead{
    text-align: center;
    img{
        width: 100px;
    }
    h2, h1{
        font-size: 20px;
    }
}


</style>